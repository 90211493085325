<template>
  <div>
    <div class="doc-title" @mouseover="open = true" @mouseout="open = false">{{title}}</div>
    <fm-poper :value="open" position="right-end">
      <div class="long-remark">
        {{title}}
      </div>
    </fm-poper>
  </div>
</template>

<script>
export default {
  data () {
    return {
      open: false
    }
  },
  methods: {
    copen () {
      this.open = !this.open
    }
  },
  props: {
    title: {
      type: String
    }
  }
}
</script>

<style lang="less" scoped>
.long-remark {
  max-width: 300px;
  border-radius: 10px;
  border: 1px solid #ccc;
  background: #fff;
  padding: 10px 15px;
}
.doc-title {
  overflow: hidden;
  max-width: 400px;
  text-overflow:ellipsis;
  white-space:nowrap;
}
</style>